import { template as template_476c6d11cf7144df9fe6e7280b8c5fab } from "@ember/template-compiler";
const FKText = template_476c6d11cf7144df9fe6e7280b8c5fab(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
