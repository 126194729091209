import { template as template_af100ebd4b9e42f8bec5ca2e75dc1500 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_af100ebd4b9e42f8bec5ca2e75dc1500(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
